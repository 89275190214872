import React, { useState } from "react";
import BubblePlot from "./BubblePlot";
import TreeGraph from "./TreeGraph";

import "./ChartsWrapper.css";

function ChartsWrapper(props) {
  const [hoveredNode, setHoveredNode] = useState("");
  const [inactiveNodes, setInactiveNodes] = useState([]);

  return (
    <div className="chart-container">
      <TreeGraph
        {...props}
        hoveredNode={hoveredNode}
        setHoveredNode={setHoveredNode}
        inactiveNodes={inactiveNodes}
        setInactiveNodes={setInactiveNodes}
      />
      <BubblePlot
        {...props}
        hoveredNode={hoveredNode}
        setHoveredNode={setHoveredNode}
        inactiveNodes={inactiveNodes}
      />
    </div>
  );
}

export default ChartsWrapper;
