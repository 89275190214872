import React, { useState, useEffect } from 'react';

function Table({ data, filters }) {
  const [sortedData, setSortedData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  useEffect(() => {
    const filteredData = data.filter(row =>
      row.Measure === filters.measure &&
      row.Approach === filters.approach &&
      (filters.year === 'Latest' ? row.Latest === '1' : true) &&
      filters.region.includes(row.Region)
    );

    const sorted = [...filteredData].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setSortedData(sorted);
  }, [data, filters, sortConfig]);

  function handleSort(key) {
    setSortConfig({
      key,
      direction: sortConfig.key === key && sortConfig.direction === 'ascending' ? 'descending' : 'ascending'
    });
  }

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th onClick={() => handleSort('Name')}>Name</th>
            <th onClick={() => handleSort('Year')}>Year</th>
            <th onClick={() => handleSort('Total Inequality')}>Total Inequality</th>
            <th onClick={() => handleSort('IOp Ex-Ante RF')}>IOp Ex-Ante RF</th>
            <th onClick={() => handleSort('IOp Ex-Post')}>IOp Ex-Post</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item, index) => (
            <tr key={index}>
              <td>{item.Name}</td>
              <td>{item.Year}</td>
              <td>{item['Total Inequality']}</td>
              <td>{item['IOp Ex-Ante RF']}</td>
              <td>{item['IOp Ex-Post']}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
