import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

function PDFViewer({ fileUrl }) {
  const pdfFile = 'https://beta-geom-1846a6caaf3c.herokuapp.com/assets/static/images/Ex%20Ante/ARG_2014_all.pdf';
  const workerUrl = 'https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js';

  return (
    <div className='pdf-container'>
      <Worker workerUrl={workerUrl}>
        <Viewer fileUrl={fileUrl} />
      </Worker>
    </div>
  );
}

export default PDFViewer;