import React, { useState, useEffect } from "react";

function CountryTable({ data, filters }) {
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (!data || !filters.country || !filters.year) {
      console.log("Data or filters are incomplete.");
      return;
    }

    const country = filters.country;
    const year = filters.year.toString();

    const filteredData = data.filter(
      (row) => row.c === country && row.y === year
    )[0];

    setFilteredData(filteredData);
  }, [data, filters]);

  return (
    <div className="">
      <p>Absolute Inequality of Opportunity</p>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Total Sample Inequality</th>
              <th>Ex-Ante Tree</th>
              <th>Ex-Ante RF</th>
              <th>Ex-Post</th>
            </tr>
          </thead>
          <tbody>
            <tr key="gini">
              <td>Gini</td>
              <td>{filteredData["Gini"]}</td>
              <td>{Number(filteredData["Gini_trees_exante"]).toFixed(4)}</td>
              <td>{Number(filteredData["Gini_rforest_exante"]).toFixed(4)}</td>
              <td>{Number(filteredData["Gini_trees_expost"]).toFixed(4)}</td>
            </tr>
            <tr key="MLD">
              <td>MLD</td>
              <td>{filteredData["MLD"]}</td>
              <td>{Number(filteredData["MLD_trees_exante"]).toFixed(4)}</td>
              <td>{Number(filteredData["MLD_rforest_exante"]).toFixed(4)}</td>
              <td>{Number(filteredData["MLD_trees_expost"]).toFixed(4)}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>Relative Inequality of Opportunity</p>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Total Sample Inequality</th>
              <th>Ex-Ante Tree</th>
              <th>Ex-Ante RF</th>
              <th>Ex-Post</th>
            </tr>
          </thead>
          <tbody>
            <tr key="gini">
              <td>Gini</td>
              <td>{filteredData["Gini"]}</td>
              <td>
                {Number(filteredData["Gini_trees_exante_rel"]).toFixed(4)}
              </td>
              <td>
                {Number(filteredData["Gini_rforest_exante_rel"]).toFixed(4)}
              </td>
              <td>
                {Number(filteredData["Gini_trees_expost_rel"]).toFixed(4)}
              </td>
            </tr>
            <tr key="MLD">
              <td>MLD</td>
              <td>{filteredData["MLD"]}</td>
              <td>{Number(filteredData["MLD_trees_exante_rel"]).toFixed(4)}</td>
              <td>
                {Number(filteredData["MLD_rforest_exante_rel"]).toFixed(4)}
              </td>
              <td>{Number(filteredData["MLD_trees_expost_rel"]).toFixed(4)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CountryTable;
